import { twMerge } from 'tailwind-merge'

import { ProfileIcon } from '@unreserved-frontend-v2/modules/users/components/user-profile/profile-icon'
import { Header } from '@unreserved-frontend-v2/ui/header/header'
import { Menu } from '@unreserved-frontend-v2/ui/menu/menu'
import { ResponsiveWidth } from '@unreserved-frontend-v2/ui/responsive-width/responsive-width'

import AppHeaderMobile from './app-header-mobile'
import { AppHeaderVariants, VARIANTS } from './constants'
import { MainMenuKey, useMenuItems } from '../hooks/use-menu-items'

export type AppHeaderProps = {
  className?: string
  pageKey?: MainMenuKey
  noBorder?: boolean
  logoWidth?: string
  variant?: AppHeaderVariants
}

export function AppHeader({
  pageKey = MainMenuKey.REAL_ESTATE,
  className,
  noBorder,
  logoWidth,
  variant = AppHeaderVariants.DEFAULT,
}: AppHeaderProps) {
  const menu = useMenuItems(pageKey)
  const variantConfig = VARIANTS[variant]

  return (
    <>
      <div
        data-testid="app-header-desktop"
        className={twMerge(
          `z-[10001] hidden h-[67px] border-b bg-white md:block`,
          variantConfig.className,
          className,
          noBorder ? 'border-transparent' : 'border-shades-200'
        )}
      >
        <ResponsiveWidth>
          <Header className="hidden md:flex" logoWidth={logoWidth} isLogoInversed={variantConfig.inverseElements}>
            <Menu items={menu} isColourInversed={variantConfig.inverseElements} />
            <ProfileIcon containerClassName="ml-7" isInversed={variantConfig.inverseElements} />
          </Header>
        </ResponsiveWidth>
      </div>
      <AppHeaderMobile className="md:hidden" />
    </>
  )
}

export default AppHeader
