import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export interface ResponsiveWidthProps {
  children: ReactNode
  className?: string
}

export function ResponsiveWidth({ children, className = '' }: ResponsiveWidthProps) {
  return <div className={twMerge(`mx-auto h-full max-w-7xl px-5`, className)}>{children}</div>
}

export default ResponsiveWidth
