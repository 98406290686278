import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

export interface MenuOverlayProps {
  items: {
    name: string
    path: string
    icon: React.ElementType
    children: React.ReactNode
  }[]
  columns?: number
  onClick: () => void
}

export function MenuOverlay({ items, columns = 2, onClick }: MenuOverlayProps) {
  //
  // Need to know at what index the items will be on the last row of the grid so that we
  // don't add any bottom padding to them.
  // Example - S is the start index of the bottom row (e.g. - 10)
  //
  //  * * * * *
  //  * * * * *
  //  S *
  //
  let lastRowIndexStart = items.length - (items.length % columns)

  //
  // Covers the edge case where the items finish on a complete row.
  // Example:
  //
  // * * * * *
  // * * * * *
  //
  if (lastRowIndexStart === items.length) {
    lastRowIndexStart -= columns
  }

  return (
    <nav className="border-shades-200 max-w-[540px] rounded border">
      <ul className="py-7 px-7">
        <div className="grid" style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}>
          {items.map(({ icon: Icon, ...item }, i) => {
            const isFirstCol = columns === 1 || (i + 1) % columns === 1
            const isLastCol = (i + 1) % columns === 0

            return (
              <div
                key={i}
                className={twMerge(
                  'flex items-center',
                  isFirstCol ? '' : 'pl-9',
                  isLastCol ? '' : 'border-shades-200 border-r border-dashed pr-9'
                )}
              >
                <li className={i < lastRowIndexStart ? 'pb-9' : ''} onClick={onClick}>
                  <Link href={item.path}>
                    <div className="flex items-center">
                      <div className="bg-shades-25 flex h-10 w-10 shrink-0 items-center justify-center rounded border border-transparent">
                        <Icon />
                      </div>
                      <div className={twMerge('pl-4')}>
                        <p className="text-shades-600 text-base font-bold">{item.name}</p>
                        {item.children ? <p className="text-shades text-sm">{item.children}</p> : null}
                      </div>
                    </div>
                  </Link>
                </li>
              </div>
            )
          })}
        </div>
      </ul>
    </nav>
  )
}

export default MenuOverlay
