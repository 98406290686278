import { faAngleDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { NoOp } from '@unreserved-frontend-v2/utils/noop'
import { ValueHash } from '@unreserved-frontend-v2/utils/types'

import MenuOverlay from '../menu-overlay/menu-overlay'

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((pkg) => pkg.FontAwesomeIcon),
  {
    ssr: false,
  }
)

export interface SubMenuListItem {
  name: string
  path: string
  icon: React.ElementType
  children: React.ReactNode
}

export interface MenuListItem {
  name: string
  path: string
  id: string
  isActive?: boolean
  subItems?: SubMenuListItem[]
  icon?: React.ElementType
  desc?: string
}

export interface MenuProps {
  items: MenuListItem[]
  isColourInversed?: boolean
}

type ItemOpenMappings = ValueHash<boolean>

/**
 * TODO: replace MUI in this component
 */
export function Menu({ items, isColourInversed = false }: MenuProps) {
  // Commenting this out as the menu items need to be consistent with each other for now,
  // Will be used later to set active and on hover state.
  const [itemOpenMap, setItemOpenMap] = useState<ItemOpenMappings>(() => {
    const map: ItemOpenMappings = {}
    items.forEach((item) => {
      map[item.name] = false
    })
    return map
  })

  const handleToggle = (itemName: string) => {
    const newMap: ItemOpenMappings = {}
    Object.keys(itemOpenMap).forEach((key) => {
      newMap[key] = key === itemName ? !itemOpenMap[key] : false
    })
    setItemOpenMap(newMap)
  }

  const handleClose = (itemId: string, itemName: string, event: Event | React.SyntheticEvent) => {
    if (getEl(itemId)?.contains(event.target as HTMLElement)) {
      return
    }
    const newMap: ItemOpenMappings = {}
    Object.keys(itemOpenMap).forEach((key) => {
      newMap[key] = key === itemName ? false : itemOpenMap[key]
    })
    setItemOpenMap(newMap)
  }

  function handleListKeyDown(itemName: string, event: React.KeyboardEvent) {
    if (event.key === 'Escape') {
      handleToggle(itemName)
    }
  }

  function isOpen(item: MenuListItem) {
    return itemOpenMap[item.name]
  }

  function getEl(itemId: string) {
    return document.querySelector(`#${itemId}`)
  }

  const getListItem = (item: MenuListItem) => {
    let activeBorder = item.isActive ? 'border-shades-400 font-bold' : 'border-transparent'
    const itemId = `composition-button-${item.id}`
    if (isColourInversed) {
      activeBorder = item.isActive ? 'border-white font-bold' : 'border-transparent'
    }

    const itemClasses = twMerge(
      `leading-[18px] border-b-2 text-base pt-6 pb-1 transition duration-200 ease-in-out block mb-4 hover:border-shades-400`,
      activeBorder,
      isColourInversed ? 'text-white hover:border-white' : ''
    )

    if (!item.subItems) {
      return (
        <Link href={item.path} shallow={true} className={itemClasses}>
          {item.name}
        </Link>
      )
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
      handleListKeyDown(item.name, event)
    }

    return (
      <>
        <button
          id={itemId}
          aria-controls={isOpen(item) ? 'true' : undefined}
          aria-expanded={isOpen(item) ? 'true' : undefined}
          aria-haspopup="true"
          onClick={() => handleToggle(item.name)}
          onKeyDown={onKeyDown}
          className={`relative pr-[15px] ${itemClasses}`}
        >
          <details
            className="relative [&[open]>.nojs-menu]:block"
            open={isOpen(item)}
            // When JS is available, state is handled in React
            onClick={(e) => e.preventDefault()}
          >
            <summary className="list-none [&::-webkit-details-marker]:hidden">{item.name}</summary>
            {!isOpen(item) ? (
              <div className="nojs-menu absolute top-[30.5px] right-0 z-[999] hidden w-[490px] bg-white drop-shadow">
                <MenuOverlay items={item.subItems || []} onClick={NoOp} />
              </div>
            ) : null}
          </details>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={twMerge(
              'absolute right-[0px] top-[25px] h-2 w-1.5 transition-transform duration-200',
              `${item.isActive ? 'font-bold' : ''}`,
              `${isOpen(item) ? '-scale-y-100 transition-transform duration-200' : ''}`
            )}
          />
        </button>
        {isOpen(item) ? (
          <Popper
            open={true}
            anchorEl={getEl(itemId)}
            role={undefined}
            placement="bottom-end"
            transition={true}
            disablePortal={true}
            sx={{
              zIndex: 10001,
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper
                  sx={{
                    border: 'none',
                    boxShadow: 'none',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    marginTop: '0',
                  }}
                  className="drop-shadow"
                >
                  <ClickAwayListener onClickAway={(event) => handleClose(itemId, item.name, event)}>
                    <div id="composition-menu" aria-labelledby={itemId} onKeyDown={onKeyDown}>
                      <MenuOverlay items={item.subItems || []} onClick={() => handleToggle(item.name)} />
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        ) : null}
      </>
    )
  }

  return (
    <nav className="flex items-center space-x-8 text-sm text-shades-600">
      {items.map((item) => (
        <div key={item.name}>{getListItem(item)}</div>
      ))}
    </nav>
  )
}

export default Menu
