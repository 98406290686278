import Logo from '../logo/logo'
import { twMerge } from 'tailwind-merge'

export interface HeaderProps {
  children?: React.ReactNode
  className?: string
  logoWidth?: string
  href?: string
  isSoloLogo?: boolean
  isLogoInversed?: boolean
  onLogoClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export function Header({
  children,
  className = '',
  logoWidth = '186',
  isSoloLogo = false,
  isLogoInversed = false,
  href,
  onLogoClick,
}: HeaderProps) {
  return (
    <div className={twMerge(`flex items-center justify-between`, className)}>
      <Logo isSolo={isSoloLogo} isInversed={isLogoInversed} width={logoWidth} href={href} onClick={onLogoClick} />
      {children ? <div className="flex items-center justify-between">{children}</div> : null}
    </div>
  )
}

export default Header
