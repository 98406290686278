import dynamic from 'next/dynamic'
import Logo from '../logo/logo'
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faTiktok,
  faXTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import Layout from '../layout/layout'
import { FlexCol } from '../flex/flex-col'
import { twMerge } from 'tailwind-merge'

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((pkg) => pkg.FontAwesomeIcon),
  {
    ssr: false,
  }
)

export interface FooterProps {
  href?: string
  containerClassName?: string
}

const social = [
  {
    icon: faFacebookF,
    link: 'https://www.facebook.com/gounreserved/',
    translationKey: 'facebook',
  },
  {
    icon: faTiktok,
    link: 'https://www.tiktok.com/@gounreserved',
    translationKey: 'tik-tok',
  },
  {
    icon: faXTwitter,
    link: 'https://twitter.com/gounreserved',
    translationKey: 'x',
  },
  {
    icon: faLinkedinIn,
    link: 'https://www.linkedin.com/company/unreserved-inc/',
    translationKey: 'linkedin',
  },
  {
    icon: faInstagram,
    link: 'https://www.instagram.com/gounreserved/',
    translationKey: 'instagram',
  },
  {
    icon: faYoutube,
    link: 'https://www.youtube.com/channel/UC7Mf7TzFFU0qyg0MU09a3GA',
    translationKey: 'youtube',
  },
]

// Commenting out links until we have the pages ready because it's throwing errors in the console
export function Footer({ href, containerClassName = '' }: FooterProps) {
  const { t } = useTranslation('footer')

  const links = [
    {
      name: t('company.terms-of-use'),
      url: '/legal/terms-of-use',
    },
    {
      name: t('company.privacy-policy'),
      url: '/legal/privacy-policy',
    },
  ]

  const menu: { groupName: string; items: { name: string; link: string }[] }[] = [
    // TODO: Hiding the menu items for now by request from Dima
    // {
    //   groupName: t('buying.title'),
    //   items: [
    //     {
    //       name: t('buying.howToBuy'),
    //       link: '#',
    //       // link: '/buying/how-to-buy',
    //     },
    //     {
    //       name: t('buying.homes-for-sale'),
    //       link: '#',
    //       // link: '/buying/homes-for-sale',
    //     },
    //   ],
    // },
    // {
    //   groupName: t('selling.title'),
    //   items: [
    //     {
    //       name: t('selling.how-to-sell'),
    //       link: '#',
    //       // link: 'selling/how-to-sell',
    //     },
    //     {
    //       name: t('selling.appraise-your-home'),
    //       link: '#',
    //       // link: 'selling/appraise-your-home',
    //     },
    //   ],
    // },
    // {
    //   groupName: t('company.title'),
    //   items: [
    //     {
    //       name: t('company.about'),
    //       link: '#',
    //       // link: 'company/about',
    //     },
    //     {
    //       name: t('company.contact'),
    //       link: '#',
    //       // link: '/company/contact',
    //     },
    //   ],
    // },
  ]

  return (
    <footer
      data-testid="app-footer"
      className={twMerge(`border-shades-200 text-shades-600 border-t bg-white py-4`, `${containerClassName}`)}
    >
      <Layout mode="desktop">
        <div className="col-span-2 space-y-3.5">
          <Logo href={href} isUnrealty={true} />
          <ul className="flex space-x-2">
            {social.map((network) => (
              <li key={network.link}>
                <a
                  href={network.link}
                  target="_blank"
                  rel="noreferrer"
                  className="block flex h-8 w-8 items-center justify-center rounded-full border"
                  aria-label={t('footer:follow-unreserved-on-x', { media: t(`footer:${network.translationKey}`) })}
                >
                  <FontAwesomeIcon icon={network.icon} />
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-start-11 flex justify-end">
          <button
            className="bg-shades-800 flex h-8 w-8 items-center justify-center rounded-full text-white focus:ring-0"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            aria-label={t('footer:back-to-top')}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </div>
        <div className="col-span-11 pt-6">
          <p className="text-shades text-sm">{t('main-sentence')}</p>
        </div>
        <div className="col-span-11 pt-6">
          <p className="text-shades text-sm">{t('trademarks')}</p>
        </div>
        <FlexCol className="col-span-11 space-y-[18px] pt-2">
          <div>
            <p className="text-shades text-sm">{t('copyright')}</p>
          </div>
          <div className="space-x-4">
            {links.map((link, i) => (
              <Link key={i} href={link.url} className="text-shades text-sm underline">
                {link.name}
              </Link>
            ))}
          </div>
        </FlexCol>
        <div className="col-span-5 col-start-4">
          <ul className="flex justify-between">
            {menu.map((group, key) => (
              <li key={key}>
                <span className="font-bold">{group.groupName}</span>
                <ul className="">
                  <div className="mt-6 space-y-2 rounded-md">
                    {group.items.map((item, key) => (
                      <li key={key}>
                        <Link href={item.link} className="hover:underline">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    </footer>
  )
}

export default Footer
